<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Header Menu Mobile Toggle-->
      <button
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
        v-if="showMenu"
      >
        <span></span>
      </button>
      <!--end::Header Menu Mobile Toggle-->
    </div>
    <!--end::Toolbar-->

    <!--begin::Logo-->
    <a href="/" v-if="showMenu">
      <img alt="Logo" :src="logoLight" class="logo-default max-h-30px" />
    </a>
    <div v-else>
      <img alt="Logo" :src="logoLight" class="logo-default max-h-30px" />
    </div>
    <!--end::Logo-->

    <!--begin::Toolbar-->
    <div class="d-flex">
      <KTLanguageBar v-if="showMenu"></KTLanguageBar>
      <KTUserbar v-if="showMenu && myInfo"></KTUserbar>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTUserbar from "@/view/layout/header/Userbar.vue";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import KTLanguageBar from "@/view/layout/header/LanguageBar.vue";

import logoLight from '@/assets/logo/logo_light.png';

export default {
  name: "KTHeaderMobile",
  components: {
    KTUserbar,
    KTLanguageBar
  },
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    myInfo() {
      return this.$store.state.myInfo;
    },
    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  },
  props: {
    showMenu: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      logoLight
    }
  }
};
</script>
